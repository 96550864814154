let config = {
  "countryCode": "DE",
  "search": "Suche",
  "production": "Produktion",
  "Receipt": "Annahme",
  "Finance": "Finanzen",
  "IT": "IT",
  "Repair": "Reparatur",
  "Support": "Support",
  "Dispatch": "Versand",
  "Customer": "Kunden",
  "Devices": "Geräte",
  "Warehouse": "Lager",
  "Product": "Produkt",
  "My Tickets": "Meine Tickets",
  "Team Tickets": "Team Tickets",
  "Create new ticket": "Erstelle ein Ticket",
  "Amount": "Menge",
  "ID": "Id",
  "Article Number": "Artikelnummer",
  "Stock Count": "Stückzahl",
  "low Stock Count": "Niedriger Stückzahl-Alarm",
  "Name": "Name",
  "Version": "Version",
  "Variant": "Variante",
  "Price": "Preis",
  "reduced_price": "Reduzierter Preis",
  "deposit": "Pfand",
  "save": "Speichern",
  "equals": "ist gleich",
  "notEquals": "ist nicht gleich",
  "greaterThan": "ist größer als",
  "lessThan": "ist kleiner als",
  "greaterThanOrEqual": "ist größer als oder gleich",
  "lessThanOrEqual": "ist kleiner als oder gleich",
  "Order not found in the System. Please Check if you inputs match your SHIFTphone8 order.": "Bestellung nicht im System gefunden. Überprüfe, ob die Eingaben mit deiner SHIFTphone8-Bestellung übereinstimmen.",
  "Email dose not match with the order mismatch. Please Check if you inputs match your SHIFTphone8 order.": "Email stimmt nicht mit der Bestellung überein. Überprüfe, ob die Eingaben mit deiner SHIFTphone8-Bestellung übereinstimmen.",
  "contains": "enthält",
  "notContains": "enthält nicht",
  "startsWith": "startet mit",
  "productInstance": "Produktinstanz",
  "endsWith": "endet mit",
  "Required_minimum_length": "Mindestens {0} Zeichen",
  "RequireOneOf": "Setze einer dieser Felder: {0}",
  "device age in Month": "Gerätealter in Monaten",
  "payed price": "Bezahlter Preis",
  "description": "Beschreibung",
  "accepted value": "Anrechnungswert",
  "no Magento order": "Keine Magento-Bestellung",
  "old Magento order": "Alte Magento-Bestellung",
  "Order is not payed!": "Bestellung ist nicht bezahlt!",
  "device price": "Gerätepreis",
  "pledge price": "Pfandpreis",
  "Workflow Actions": "Workflow-Aktionen",
  "Dispatch Tickets": "Versand Tickets",
  "Finance Tickets": "Finanz Tickets",
  "Create a repair Child Ticket": "Erstelle ein Reparatur Sub-Ticket",
  "Create a return Ticket": "Erstelle ein Rückgabe Ticket",
  "Receipt Tickets": "Annahme Tickets",
  "Confirmation": "Bestätigung",
  "no shipment address": "Keine Lieferadresse",
  "edit address": "Adresse bearbeiten",
  "create address": "Adresse hinzufügen",
  "Repair Tickets": "Reparatur Tickets",
  "only for SL-refurbishment": "Nur für SL-Refurbishment",
  "total price": "Gesamtpreis",
  "offer accepted": "Angebot angenommen",
  "magento order number": "Magento-Bestellnummer",
  "surrenderValue": "Rückkauf Wert",
  "Missing child ticket": "Es fehlt das subticket",
  "Child Ticket": "Subticket",
  "in wrong state": "im falschen Status",
  "Customer Created with ID": "Kunde angelegt mit ID",
  "Please use the protocol for further nodes! Only initial information should be entered here.": "Bitte verwenden Sie das Protokoll für die weiteren Kommentare! Nur Initialinformationen sollten hier eingegeben werden.",
  "Do you want to batch create multiple product instances?": "Möchten Sie mehrere Produktinstanzen als Batch erstellen?",
  "Do you want to close the Test?": "Möchtest du den Test schließen?",
  "Invalid postcode or country not supported": "Ungültige Postleitzahl oder Land nicht unterstützt",
  "expect:": "erwartet:",
  "Create a sub-ticket": "Erstelle ein Unterticket",
  "Create a product instance and add it to the ticket": "Erstelle ein Produktinstanz und füge es dem Ticket hinzu",
  "Please confirm": "Bitte bestätigen",
  "Create a new customer": "Erstelle einen Kunde",
  "'": "'",
  "$vuetify": {
    "open": "Öffnen",
    "badge": "Hinweis",
    "close": "Schließen",
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "page": "Seite",
        "currentPage": "aktuelle Seite",
        "next": "weiter",
        "previous": "zurück"
      }
    },
    "noDataText": "Keine Daten vorhanden",
    "dataFooter": {
      "itemsPerPageAll": "Alle Artikel pro Seite",
      "itemsPerPageText": "Artikel pro Seite",
      "pageText": "{0}-{1} von {2}",
      "prevPage": "Vorherige Seite",
      "firstPage": "Erste Seite",
      "nextPage": "Nächste Seite",
      "lastPage": "Letzte Seite"
    },
    "datePicker": {
      "header": "Datumsauswahl",
      "title": "Datum auswählen"
    },
    "stepper": {
      "next": "Weiter",
      "prev": "Zurück"
    }
  },
  "__typename": "Artikeltyp",
  "Error while preparing action": "Fehler beim Vorbereiten der Aktion",
  "No mobile devices connected": "Kein Handy mit deinem Account verbunden.",
  "Please connect your mobile devices!": "Bitte verbinde dein Handy!",
  "Address Type": "Address Type",
  "prefix": "Präfix",
  "counter": "Zähler",
  "suffix": "Suffix",
  "first name": "Vorname",
  "last name": "Nachname",
  "middle name": "Mittlerer Name",
  "street and no": "Straße und Hausnummer",
  "street and house number": "Straße und Hausnummer",
  "postal code": "PLZ",
  "postcode": "PLZ",
  "country_id": "Land ID",
  "city": "Stadt",
  "region": "Region",
  "country": "Land",
  "email": "E-Mail",
  "not specified": "nicht angegeben",
  "phone": "Telefonnummer",
  "company": "Firma",
  "add": "hinzufügen",
  "address": "Address",
  "firstname": "Vorname",
  "lastname": "Nachname",
  "street": "Straße",
  "Login for your mobile device": "Login für dein Handy",
  "Do you really want to delete this address?": "Willst du wirklich die Adresse löschen?",
  "This customer has no assigned addresses yet": "Dieser Kunde hat noch keine Adressen zugewiesen",
  "Select a address to copy": "Wähle eine Addresse zu kopieren",
  "Add File": "File hinzufügen",
  "Error uploading picture": "Fehler beim Hochladen des Bildes",
  "Take Picture": "Bild machen",
  "picture name": "Bildname",
  "upload pictures": "Bildr hochladen",
  "upload picture": "Bild hochladen",
  "Barcode Detector": "Barcode Detector",
  "Scan QR-Code, Barcode or something else": "Scan QR-Code, Barcode oder etwas anderes",
  "Close": "Schließen",
  "Application Overview": "Anwendungsübersicht",
  "Actions": "Aktionen",
  "gender": "Geschlecht",
  "Gender": "Geschlecht",
  "create": "erstellen",
  "Saved": "gespeichert",
  "Customer property": "Kunden Eigenschaft",
  "Detailes are shown": "Detailes werdem angezeigt",
  "Detailes are hidden": "Detailes sind versteckt",
  "cancel": "abbrechen",
  "Cancel": "Abbrechen",
  "Addresses": "Adressen",
  "Attachments": "Anhänge",
  "Related Products": "Verbundene Artikel",
  "Related Tickets": "Verbundene Tickets",
  "Company": "Firma",
  "title": "title",
  "main email": "Haupt-E-Mail",
  "email2": "email2",
  "make main E-Mail": "zur hauptadresse machen",
  "email3": "email3",
  "address_text": "address_text",
  "Same shipment address": "gleiche Versandadresse",
  "Next": "Next",
  "Size": "Größe",
  "Revocation": "Widerruf",
  "Upgrade": "Upgrade",
  "upgrade": "Upgrade",
  "Return other": "Rückgabe sonstiges",
  "Return used device": "Rückgabe eines Gebrauchten Geräts",
  "No History": "Keine History",
  "Show Migration Data": "Zeige die Migration-Daten",
  "show migration raw data": "Zeige die Rohen Migration-Daten",
  "global search": "Globale suche",
  "Searching in the following fields:": "Suche in den folgenden Feldern:",
  "entity id": "entity id",
  "Magento Order": "Magento-Bestellung",
  "open order in Magento": "öffnene Bestellung in Magento",
  "opens in new window": "in einem neuen Fenster öffnen",
  "tax amount": "Steuer kosten",
  "grand total": "grand total",
  "shipping incl tax": "versand inkl. MwSt.",
  "payment method": "Bezahlmethode",
  "tax name": "Stuer name",
  "tax rate": "Steuer",
  "Order Items": "Bestellpositionen",
  "quantity ordered": "Bestellmenge",
  "base price": "Basispreis",
  "No record": "Kein Eintrag",
  "base price incl tax": "basispreis inkl. MwSt.",
  "Order Comments": "Bestellungskommentare",
  "Please set Name (*)": "Bitte geben Sie den Namen (*) ein",
  "address_type": "Adresstyp",
  "status": "Stand",
  ", created at": ", erstellt am",
  "Email": "E-Mail",
  "Street": "Straße",
  "Scan Product-Instance 2 Desktop": "Geräteausfruf in Desktop-PC",
  "City": "Stadt",
  "Postal Code": "PLZ",
  "Country": "Land",
  "Magento Order Number": "Magento-Bestellnummer",
  "Submit Number": "Nummer Hinzufügen",
  "Add Products / Tracking Code": "Hinzufügen von Produkten / Tracking-Code",
  "Restart All": "Alles neustarten",
  "Create Shipment Ticket": "Erstelle ein Versandticket",
  "Cancel Request": "Anfrage Abbrechen",
  "Back": "zurück",
  "No Request!": "Derzeit keine Anfrage!",
  "Waiting for requests from Desktop PC!": "Warten auf Auftrag vom Desktop-PC!",
  "Scan Barcode 4 Desktop": "Text eingabe in Desktop-PC",
  "Inspect Product Instance": "Geräteaufruf",
  "Product Instance": "Produktinstanz",
  "Test a device": "Test für ein Gerät erstellen",
  "Create Shipping Ticket": "Versand Ticket erstellen",
  "Add": "hinzufügen",
  "Continue": "Weiter",
  "Are you sure you want to delete this filter?": "Bist du sicher, dass du den Filter löschen möchtest?",
  "Closed": "Schließen",
  "updated: ": "updated: ",
  "Open Ticket": "Öffne das Ticket",
  "Are you sure you want to delete this item?": "Bist du sicher, dass du löschen möchtest?",
  "No Device": "Kein Gerät",
  "Upgrade Offer": "Upgradeangebot",
  "Add device 2 upgrade": "Füge ein Gerät zum Upgrade hinzu",
  "upgrade price": "Upgrade-Kosten",
  "No Order Number": " Keine Bestellnummer",
  "Old Device": "Altes Gerät",
  "deviceAge": "Gerät ist vor {age} Tagen verkauft worden",
  "Add old device to upgrade": "Altes Gerät zum Upgrade hinzufügen",
  "Add new device": "Neues Gerät hinzufügen",
  "Open existing Test": "Öffne einen bestehenden Test",
  "Upgrade Order": "Upgrade",
  "Billing Address": "Rechnungsadresse",
  "billing": "Rechnung",
  "Amount of Products": "Anzahl von Produkten",
  "Select Product": "Produkt auswählen",
  "Save": "Speichern",
  "processing": "Verarbeitung",
  "Repair Cost": "Reparaturkosten",
  "Total: before tax": "Gesammt: vor Steuern",
  "before tax": "vor Steuern",
  "Total: after tax (19%)": "Gesammt: nach Steuern (19%)",
  "after tax (19%)": "nach Steuern (19%)",
  "Add Products to Ticket": "Füge ein Produkt zum Ticket hinzu",
  "Variant:": "Variante:",
  "Serial number": "Seriennummer",
  "IMEI 1": "IMEI 1",
  "IMEI 2": "IMEI 2",
  "Description": "Beschreibung",
  "Device property": "Geräteeigenschaften",
  "State": "Status",
  "quality": "Qualität",
  "details are shown": "Details werden gezeigt",
  "details are hidden": "Details sind versteckt",
  "state": "Status",
  "internal-ticket": "interner Ticket",
  "internal-customer": "interner Kunde",
  "Should be": "sollte sein",
  "but is": "aber ist",
  "serialnumber": "Seriennummer",
  "Produced at": "Produktionsdatum",
  "Productname": "Produktname",
  "SN": "SN",
  "Logout": "Logout",
  "Additional Products": "Zusätzliche Produkte",
  "Still open Tests": "unbearbeitete Tests",
  "Change Profile Config": "Ändere Profil-Konfiguration",
  "My Settings": "Meine Einstellungen",
  "This Ticket is connected to a stolen product": "Dises Ticket ist mit einem gestohlenen Produkt verbunden",
  "Are you sure you want to delete this relation?": "Bist du sicher, dass du diese Verbindung löschen möchtest?",
  "Product Code": "Produkt-Code",
  "Date ID": "Datum Kennung",
  "Herstellungsdatum": "Herstellungsdatum",
  "Product SN": "Produt SN",
  "My Profile": "Mein Profil",
  "Username": "Username",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Not specified": "Nicht spezifiziert",
  "Teams": "Teams",
  "New Protocol": "Neues Protokoll",
  "update": "update",
  "Move tasks to this Ticket": "Verschiebe Aufgaben zu diesem Ticket",
  "Move Task to the Child Ticket": "Verschiebe Aufgabe zu diesem Sub Ticket",
  "product ID": "Produkt ID",
  "product Name": "Produktname",
  "product name": "Produktname",
  "Select the reason of the return": "Wähle den Grund der Rückgabe",
  "Ticket detailes are hidden": "Ticket Details sind versteckt",
  "Ticket detailes are shown": "Ticket Details werden angezeigt",
  "Input not valid. Please check!": "Eingabe ungültig. Bitte überprüfe!",
  "no due date": "kein Fälligkeitsdatum",
  "store amount": "Lager Anzahl",
  "last change": "letzte Änderung",
  "book in": "book in",
  "book out": "book out",
  "store": "store",
  "open": "öffnen",
  "Magento order shipment payment": "Magento Order Versand zahlung",
  "": "",
  "Active columns": "Sichtbare Felder",
  "select date": "wähle ein Datum",
  "select start date": "wähle ein Startdatum",
  "select end date": "wähle ein Enddatum",
  "Add Filter": "Filter hinzuzufügen",
  "No filters are set": "Keine Filter sind gesetzt",
  "filter name": "Filter Name",
  "Save Filter": "Filter speichern",
  "Stored Filter": "Gespeicherte Filter",
  "Delete Filter": "Filter löschen",
  "Reload Filter": "Filter neue laden",
  "Search in following fields:": "Suche in folgenden Feldern:",
  "Tasks": "Aufgaben",
  "Task description": "Aufgaben Beschreibung",
  "Store Property": "Store Property",
  "Template Name": "Template Name",
  "Please load a Ticket for the preview": "Bitte lade ein Ticket für die Vorschau",
  "add PDF to Ticket": "füge das PDF zum Ticket hinzufügen",
  "select": "Auswählen",
  "add directly": "PDF direkt anhängen",
  "No product instance is selected!": "Keine Produktinstanz ausgewählt!",
  "Select a test": "Wähle einen Test aus",
  "Quality Result": "Qualitäts Ergebnis",
  "Set Quality": "Setze Qualität",
  "Success": "Erfolgreich",
  "Error": "Fehler",
  "Undo": "Rückgängig",
  "Close Reason": "Close Reason",
  "Close Ticket": "Schließe Ticket",
  "Ticket": "Ticket",
  "ticket name": "Ticket Name",
  "ticket typ": "Ticket-Typ",
  "ticket id": "Ticket-ID",
  "id": "ID",
  "Missing Customer connection": "Kundenverbindung fehlt",
  "Missing customer": "Kunde fehlt",
  "ticket": "Ticket",
  "customer": "Kunde",
  "Ticket property": "Ticket Eigenschaften",
  "Ticket Type": "Ticket Type",
  "Created at": "Erstellt am",
  "Please select a ticket": "Bitte wähle ein Ticket",
  "All Tasks": "Alle Aufgaben",
  "Workflow actions": "Workflow Aktionen",
  "Workflow preview": "Workflow Vorschau",
  "GoTo: ": "GoTo: ",
  "Workflow": "Workflow",
  "User course": "Benuter Arbeitsabläufen",
  "touch date is undefined": "touch date is undefined",
  "is undefined": "ist nicht definiert",
  "ticket type is undefined": "ticket type ist nicht definiert",
  "Options": "Optionen",
  "Delete": "Löschen",
  "Really delete this Object?": "Willst du wirlisch löschen?",
  "Yes": "Ja",
  "No": "Nein",
  "Add new element from input text": "Füge ein neues Element hinzu",
  "select the due date": "wähle ein Fällichkeitsdatum",
  "Clear": "Löschen",
  "Close Date Picker": "Schließe den Date Picker",
  "Invalid date Object": "Ungültiges Datum",
  "Invalid date": "Ungültiges Datum",
  "Need to be a string": "Muss eine Zeichenkette sein",
  "Invalid Email": "Email ist nicht gültig",
  "IMEI needs digits only": "IMEI darf nur Zahlen enthalten",
  "IMEI needs 15 digits": "IMEI braucht 15 Ziffern",
  "Add new Filter": "Filter hinzufügen",
  "Check sum is not valid": "Die Checksumme ist nicht gültig",
  "IMEI2 not next to IMEI1": "IMEI2 ist nicht neben IMEI1",
  "Order number must be a number": "Order number muss eine Zahl sein",
  "Order number must be 9 digits": "Order number muss 9 zeichen haben",
  "Number_to_small": "Nummer ist zu klein",
  "Value must only contain digits": "Dieser Parameter darf nur Zahlen enthalten",
  "Invalid Phone Number example: +49 1234 567890": "Ungültige Telefonnummer! Beispiel: +49 1234 567890",
  "This Parameter is required": "Dieser Parameter ist erforderlich",
  "Value must only contain letters": "der Wert darf nur Buchstaben enthalten",
  "Value must be a string": "der Wert muss eine Zeichenkette sein",
  "Tracking Code needs digits only": "Tracking-Code darf nur Zahlen enthalten",
  "Tracking Code needs to equal 20 digits": "Der Tracking-Code muss 20 Zeichen haben",
  "ticket-no": "Ticketnummer",
  "Ticket-no": "Ticketnummer",
  "support-no": "Supportnummer",
  "hardware_definition-no": "Hardwaredefinitionnummer",
  "Teamplates": "Teamplates",
  "quality_assurance-no": "Qualitätssicherungsnummer",
  "quality_assurance": "Qualitätssicherung",
  "repair-no": "Reparaturnummer",
  "due date is today": "Fälligkeitsdatum ist heute",
  "return-no": "Rückgabe-Nummer",
  "is not in expected state: ": "ist nicht in erwartetem Status: ",
  "delivery-no": "Lieferungsnummer",
  "shipment-no": "Versandnummer",
  "priority": "Priorität",
  "Team": "Team",
  "Delete Address": "Adresse löschen",
  "Creation Date": "Erstellungsdatum",
  "Sub type": "Untertyp",
  "Magento State": "Magento Status",
  "Magento Number": "Magento Nummer",
  "Protocols": "Protokolle",
  "Advance Device Deposit": "Vorauszahlung",
  "Edit Address": "Adresse bearbeiten",
  "Ticket Sub Type": "Ticket Sub Typ",
  "Type": "Typ",
  "Delete table config": "Lösche die Tabellen Konfiguration",
  "Reload table config": "Lade die Tabellen Konfiguration neu",
  "Load a Table Config": "Lade eine Tabellen Konfiguration",
  "Save the current table config": "Erstelle eine neue Tabellen Konfiguration",
  "Update current table config": "Aktualisiere die aktuelle Tabellen Konfiguration",
  "Add a new Filter": "Füge ein neues Filter hinzu",
  "Change column config": "Ändere die Spalten Konfiguration",
  "Export table to Excel": "Exportiere die Tabelle in Excel",
  "due date": "Fälligkeitsdatum",
  "not in": "nicht in",
  "Add a new protocol": "Füge ein neues Protokoll hinzu",
  "Add a new Address to the ticket": "Füge eine neue Adresse zum Ticket hinzu",
  "Add an existing ticket as a child": "Füge ein bestehendes Ticket als Sub Ticket hinzu",
  "sub type": "Untertyp",
  "Create a Ticket": "Erstelle ein Ticket",
  "Create a sub Ticket": "Erstelle ein Sub Ticket",
  "Create a product Instance": "Erstelle eine Produktinstanz",
  "Ticket State": "Ticket Status",
  "Select the diagnosis": "Wähle die Diagnose",
  "createdAt": "Erstellungsdatum",
  "updatedAt": "Änderungsdatum",
  "Templates": "Templates",
  "remove attachment from ticket": "Löschene Anhang vom Ticket",
  "Return Reason": "Rückgabegrund",
  "Diagnosis": "Diagnose",
  "This customer has no assigned addresses yet.": "Dieser Kunde hat noch keine Adressen zugewiesen.",
  "female": "weiblich",
  "nonbinary": "nicht binär",
  "generate PDF": "generiere PDF",
  "Reload preview": "Neue Vorschau laden",
  "Load Ticket": "Ticket laden",
  "Back to edit view": "Zurück zur Bearbeitungsansicht",
  "Your Text": "Dein Text",
  "Add a new Address to the Customer": "Füge eine neue Adresse zum Kunden hinzu",
  "Add an existing ticket": "Füge ein bestehendes Ticket hinzu",
  "ready for next Step": "Bereit für den nächsten Schritt",
  "male": "männlich",
  "Shipping": "Versand",
  "Add Product Instance to the Ticket": "Füge eine neue Produkt Instanz zum Ticket hinzu",
  "create a Product Instance and add to the Ticket": "Erstelle eine neue Produkt Instanz und füge sie dem Ticket hinzu",
  "name": "Name",
  "Create a new Customer": "Erstelle einen neuen Kunden",
  "Connect a customer to this ticket": "Verbindung einen Kunden zu diesem Ticket",
  "Connect customer over the ProductInstance": "Verbindung einen Kunden über die Produkt Instanz",
  "Copy Address from Customer to Ticket": "Kopiere eine Adresse vom Kunden zum Ticket",
  "Add Product Instance to the Ticket by scanner": "Scanne eine Produkt Instanz und füge sie dem Ticket hinzu",
  "Add a picture with the smartphone": "Mache ein Bild mit dem Smartphone",
  "Add an attachment": "Füge ein Anhang hinzu",
  "key": "key",
  "User is disabled": "Benutzer ist deaktiviert",
  "last_hit": "letzte Benutzung",
  "Merge Customers": "Zusammenführen von Kunden",
  "All Tickets will be merged!": "Alle Tickets werden zusammengeführt!",
  "absorb customer": "Zusammenführen von Kunden",
  "Target Customer": "Zielkunde",
  "Customer to be absorbed": "Kunde, der zusammengeführt werden soll",
  "Show history": "Zeige die Verlaufshistorie",
  "medium": "Normal",
  "stolen": "Gestohlen",
  "Show Config": "Zeige die Config",
  "language": "Sprache",
  "Delete Ticket": "Lösche das Ticket",
  "on": "on",
  "day": "Tag",
  "Created by": "Erstellt von",
  "Product U. Name": "Produkt U. Name",
  "Add some amount of products to the ticket": "Füge eine Anzahl an Produkten zum Ticket hinzu",
  "There are still open Tasks/ToDos": "Es gibt immer noch offene Aufgaben/ToDos",
  "have passed since the due date": "nach Fälligkeitsdatum",
  "days": "Tage",
  "past": "vergangen",
  "Missing product": "Fehlende Produkte",
  "to go": "noch",
  "support": "Support",
  "Quality": "Qualität",
  "SubState": "Unter Status",
  "Product Instance to Repair": "Produkt Instanz zu reparieren",
  "Product to Repair": "Produkt zu reparieren",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "Phone": "Telefonnummer",
  "Phone number": "Telefonnummer",
  "Update Date": "Änderungsdatum",
  "Missing Translations": "Fehlde Übersetzungen",
  "repair": "Reparatur",
  "until the due date": "bis zum Fälligkeitsdatum",
  "GoTo": "Gehe zu",
  "not empty": "nicht leer",
  "Name Only": "Nur Name",
  "Ticket Name": "Ticket Name",
  "Create a product instance": "Erstelle eine Produktinstanz",
  "Create a customer": "Lege einen Kunden an",
  "Create a template": "Erstelle ein Template",
  "Create a ticket": "Erstelle ein Ticket",
  "Missing product_instances": "Fehlende Produkt Instanzen",
  "high": "Hoch",
  "low": "Niedrig",
  "shipment": "Lieferung",
  "remove child ticket from ticket": "Lösche das Sub Ticket vom Ticket",
  "advance device deposit": "Vorgerät Pfand",
  "loanDevice": "Leihgerät",
  "Protocol": "Protokoll",
  "upgradeDifferencePrice": "Upgrade-Differenzpreis",
  "Related Product Instances": "Zugehörige Produkt Instanzen",
  "no": "Nr",
  "empty": "leer",
  "name not defined": "Name nicht definiert",
  "undefined": "nicht definiert",
  "User not found": "Benutzer nicht gefunden",
  "Parameter is missing:": "Parameter fehlt:",
  "Residual Order": "Rückkauf Auftrag",
  "Parent Tickets": "Super Tickets",
  "Child Tickets": "Sub Tickets",
  "Related Customer": "Zugehörige Kunden",
  "Repair parts": "Reparatur Teile",
  "Please set name": "Bitte gebe einen Namen an",
  "create_options_title": "Erstellungsoptionen",
  "create_options_description": "In dieser Aktionsliste können Tickets, Kunden, Produktinstanzen oder Templates für Formulare angelegt werden. Diese Objekte können später miteinander verknüpft oder als Ausgangspunkt für neue Anfragen verwendet werden.",
  "history_title": "Verlauf",
  "history_description": "Die Verlaufsliste bietet einen schnellen Überblick über die Tickets, die Sie zuletzt aufgerufen haben.",
  "notification_title": "Benachrichtigungen",
  "notification_description": "Benachrichtigungen halten Sie über Änderungen an Tickets, die Sie verfolgen, auf dem Laufenden.",
  "Are you sure you want to cancel this ticket?": "Sind Sie sicher, dass Sie dieses Ticket abbrechen möchten?",
  "Settings": "Einstellungen",
  "Translation": "Übersetzung",
  "No Magento Order Number found in the ticket": "Kein Magento-Bestellnummer für dieses Ticket gefunden",
  "ready": "bereit",
  "pin": "PIN",
  "Magento Order Loan Device": "Magento-Bestellnummer Leihgerät",
  "and": "und",
  "muster": "Muster",
  "Not possible": "Nicht möglich",
  "possible": "möglich",
  "already done": "bereits erledigt",
  "other": "andere",
  "Finished": "Abgeschlossen",
  "Current": "Aktuell",
  "create a Ticket from Product Instance Scanner": "Erstelle ein Return Ticket mit dem Produkt Instanz Scanner",
  "or": "oder",
  "\n": "\n",
  "Arrows": "Pfeile",
  "order info": "Bestellinformationen",
  "order id": "Bestellnummer",
  "order date": "Bestelldatum",
  "Package Checked": "Paket überprüft",
  "not required": "nicht erforderlich",
  "compensation type": "Vergütungsart",
  "device access info": "Gerätezugriffsinformationen",
  "contact info": "Kontaktinformationen",
  "complete": "Abgeschlossen",
  "States": "Stati",
  "create inquiry": "Rückfrage erstellen",
  "Answer inquiry": "Beantworte die Rückfrage",
  "Question": "Frage",
  "Order number": "Bestellnummer",
  "Please enter your Order number": "Bitte geben Sie Ihren Ordnernummer ein",
  "Please enter your IMEI numbers": "Bitte geben Sie Ihre IMEI-Nummern ein",
  "task_open": "{count} ist offen | {count} sind offen",
  "task_closed": "{count} ist geschlossen | {count} sind geschlossen",
  "address type": "Typ der Adresse",
  "Please set Name": "Bitte gebe einen Namen an",
  "Update my Roles": "Aktuallisiere meine Rechte/Rollen",
  "Update Table Config": "Ändere die Tabellen Konfiguration",
  "back": "zurück",
  "Create new Table Config": "Erstelle eine neue Tabellen Konfiguration",
  "Table config saved successfully": "Tabellen Konfiguration erfolgreich gespeichert",
  "Magento-Order-Number post deposit": "Magento-Bestellung nachträgliches Pfand",
  "My Roles": "Meine Rechte/Rollen",
  "Show ticket raw data": "Zeige Ticket als roher Daten",
  "due_date is missing": "Fälligkeitsdatum fehlt",
  "Search for a customer in Magento based on the Magento Order Number": "Suche nach einem Kunden in Magento basierend auf der Magento-Bestellnummer",
  "Only PNG, JPEG, GIF, TXT, PDF, LibreOffice, and Microsoft Office files are allowed": "Nur PNG, JPEG, GIF, TXT, PDF, LibreOffice und Microsoft Office-Dateien sind erlaubt",
  "Each file must be smaller than": "Jede Datei muss kleiner sein als {maxSizeInMB}MB",
  "Order number must start with 9 digits followed by an optional hyphen and another digit": "Die Bestellnummer muss mit 9 Zahlen beginnen und eventuell einen optionalen Bindestrich und dann eine weitere Zahl folgen",
  "_workflow": {
    "_name": {
      "Upgrade Order Deposit included": "Upgrade Bestellung Pfand eingeschlossen",
      "Upgrade Order Deposit PostPayed": "Upgrade Bestellung Pfand Nachbestellt",
      "Upgrade Order return first": "Upgrade Bestellung ohne Pfand",
      "Kombiangebot without deposit": "Kombiangebot ohne Pfand",
      "Kombiangebot with deposit": "Kombiangebot mit Pfand",
      "loan_device_workflow_order_no_deposit": "Leihgerät-Workflow Bestellung ohne Pfand",
      "loan_device_workflow_order_with_deposit": "Leihgerät-Workflow Bestellung mit Pfand",
      "combiOfferWithDeposit": "Kombiangebot mit Pfand",
      "combiOffer": "Kombiangebot ohne Pfand",
    },
  },
  "_magentoOrder": {
    "subtotal": "subtotal",
    "base_tax_amount": "Basissteuerbetrag",
    "base_grand_total": "Gesammtbetrag",
    "shipping_description": "Versandbeschreibung",
    "shipping_incl_tax": "Versand inkl. Steuern",
    "payment_method": "Zahlungsmethode",
    "tax_name": "Steuername",
    "tax_rate": "Steuersatz",
    "_orderItem": {
      "qty_ordered": "Bestellmenge",
      "base_price": "Basispreis",
      "base_price_incl_tax": "Basispreis inkl. Steuern",

    },
    "_order_comments": {
      "status": "Kommentarstatus",
      "comment": "Kommentar",
      "created_at": "Erstellungsdatum"
    },
  },
  "_templateBuilder": {
    "prevStep1": "Zurück",
    "prevStep2": "Zurück zur Bearbeitung",
    "prevStep3": "Zurück zur Vorschau",
    "nextStep1": "Vorschau anzeigen",
    "nextStep2": "PDF anzeigen",
    "nextStep3": "Weiter",
    "titleEdit": "Template-Bearbeiten",
    "titlePreview": "Template-Vorschau",
    "titleGenerate": "PDF-Vorschau",
    "alertTicketIsRequired": "Zur Erzeugung der Template-Vorschau muss eine Ticket-ID vergeben werden.",
    "alertPreviewIsRequired": "Lade zuerst die Template-Vorschau, um das PDF anhängen zu können.",
    "create": "Template anlegen",
    "add": "PDF anhängen",
    "loadingText": "Lade...",
    "loadingDescription": "PDF wird erstellt und dem Ticket angehängt. Bitte warten einen Moment.",
  },
  "_dialogReturn": {
    "header": {
      "formType": "Grund für die Rückgabe",
      "address": "Adresse angeben",
      "credentials": "Zugangsdaten eingeben",
      "deposit": "Gerätepfand Verwendung",
      "orderInfo": "Bestellinformationen eingeben",
      "finished": "Abschluss des Antrags",
      "deviceInfo": "Geräte-Identifikation und Fehlerbeschreibung",
      "confirmation": "Bestätigung"
    },
    "orderInfo": {
      "title": "Gerät-Bestellangaben",
      "labelOrderId": "Bestellnummer",
      "labelOrderDate": "Bestelldatum",
      "info": "Bitte Bestellnummer angeben. Dies hilft uns, deinen Fall schneller zu finden und zu bearbeiten. Wenn du deine Bestellnummer verlegt hast, gib bitte das ungefähre Datum deiner Bestellung an.",
      "errorOrderId": "Die Bestellnummer ist ungültig.",

    },
    "formType": {
      "title": "Rückgabengrund",
      "repair": "Reparatur",
      "upgrade": "Upgrade",
      "return": "Rückgabe",
      "revocation": "Widerruf",
      "labelReturnReason": "Grund des Widerrufs",
      "infoRepair": "Du hast ein defektes Gerät und möchtest es reparieren lassen.",
      "infoUpgrade": "Du möchtest dein altes Gerät gegen ein neues eintauschen. Dabei wird der Restwert deines alten Gerätes ermittelt und mit dem Kaufpreis des neuen Gerätes verrechnet.",
      "infoReturn": "Du möchtest dein altes Gerät zurückgeben und den Restwert erstattet bekommen oder du möchtest, dass das Gerät dem Recycling zugeführt wird..",
      "infoRevocation": "Du möchtest von deinem Widerrufsrecht Gebrauch machen und das Gerät zurückgeben.",
    },
    "address": {
      "billing": "Rechnungsanschrift",
      "shipping": "Versandanschrift",
      "self_pickup": "Selbstabholung",
      "same_as_billing": "Ist identisch mit der Rechnungsanschrift",
      "noValidBillingAddress": "Rechnungsanschrift bitte vollständig ausfüllen.",
      "noValidShippingAddress": "Versandanschrift bitte vollständig ausfüllen.",
      "errorMessage": "Bitte füllen Sie alle mit * gekennzeichneten Felder korrekt aus.",
      "errorCountry": "Es wird nur deutschlandweit versendet.",
    },
    "pin": {
      "title": "PIN-Code oder Muster eingeben",
      "label": "PIN-Code eingeben",
      "warningFourValues": "Gib eine PIN oder ein Muster mit mindestens vier Werten an.",
      "info": "Wir möchten dich darauf hinweisen, dass wir keine Garantie für die Erhaltung der Daten auf dem Gerät übernehmen können. Unter der Voraussetzung, dass wir Zugriff auf das Gerät haben, besteht eine größere Wahrscheinlichkeit, dass die Daten erhalten bleiben."
    },
    "deviceInfo": {
      "titleDesignation": "Name des Gerätes",
      "titleImei": "Mobile Geräte-Identifikation",
      "titleSerialnumber": "Desktop-Geräte-Identifikation",
      "designation": "Gerätbezeichnung",
      "designationPlaceholder": "SHIFTbook, SHIFT6mq, SHIFTsound BNO ...",
      "imei1": "IMEI 1",
      "imei2": "IMEI 2",
      "sn": "Seriennummer (SN)",
      "infoImei": "Die International Mobile Equipment Identity (IMEI) ist eine Nummer, die jedes Mobiltelefon und Smartphone eindeutig identifiziert. Sie besteht aus 15 Stellen und befindet sich im Akkufach.",
      "infoSerialnumber": "SHIFTbooks besitzen eine Seriennummer (SN). Sie besteht aus 15 Stellen und befindet sich neben dem Akkufach.",
      "titleErrorDescription": "Fehlerbeschreibung"
    },
    "deposit": {
      "titleDeviceDeposit": "Gerätepfand",
      "consultation": "wird nach Rücksprache festgelegt",
      "coupon": "als Shop-Gutschein",
      "donate": "als Spende",
      "transfer": "als Überweisung",
      "titleBankDetails": "Bankverbindungsdaten",
      "accountOwner": "Konto-InhaberIn",
      "iban": "IBAN",
      "bic": "BIC",
      "error": "Die Angaben zum Konto sind nicht korrekt oder nicht vollständig ausgefüllt."
    },
    "confirmation": {
      "repair": "Reparatur-Formular",
      "upgrade": "Upgrade-Formular",
      "return": "Widerruf-Formular",
      "revocation": "Einsendungsantrag",
      "errorForm": "Es ist ein Fehler aufgetreten.",
      "self_pickup": {
        "yes": "Ich möchte das Gerät selbst abholen",
        "no": "Ich möchte die Zusendung des Gerätes per Post erhalten"
      },
      "attachments": {
        "label": "Anlage hinzufügen"
      },
      "subheader": {
        "receive": "Art der Zustellung",
        "contactInfo": "Kontaktinformationen",
        "orderInfo": "Bestellinformationen",
        "deviceAccessInfo": "Gerätezugriffsinformationen",
        "deposit": "Pfandvergütung",
        "errorDescription": "Angaben über das abzugebende Gerät",
        "attachments": "Bilder oder anderes Material dem Formular hinzufügen"
      },
      "infoSwitzerland": "Wenn du aus der Schweiz kommst, wende dich bitte an unsere Freunde von",
      "switzerlandLinkText": "aldernativ.ch",
      "switzerlandLink": "https://aldernativ.ch",
      "infoNotEuLand": "Sendungen aus Nicht-EU-Ländern können nicht angenommen werden.",
      "confirmationText": "Mit der Einsendung bestätige ich, dass ich die Einsende- und Reparaturhinweise unter #https://shift.eco/reparatur# gelesen und akzeptiert habe.",
      "repairLinkText": "shift.eco/reparatur",
      "repairLink": "https://shift.eco/reparatur",
      "submitForm": "Formular abschicken",
      "formHasBeenSubmitted": "Formular wurde gesendet",
      "infoReloadPage": "Die Seite wird in 10 Sekunden neu geladen."
    },
    "requestCompleted": {
      "title": "Ihre Anfrage wurde gesendet",
      "titleError": "Fehler bei der Übertragung",
      "info": "Ihr Ticket ist erstellt und wird bearbeitet. Bitte notieren Sie sich Ihre Ticketnummer und legen Sie sie Ihrer Sendung bei.",
      "infoError": "Beim Absenden der Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "infoFileError": "Fehler bei der Übertragung der angehängten Dateien",
      "label": "Ticket-Nummer",
      "linkRefresh": "Startseite",
      "linkSendAgain": "Erneut senden",
      "ticketNumber": "Ticketnummer",
      "text": "Bitte notieren Sie sich Ihre Ticketnummer. Dies erleichtert uns bei Rückfragen das Auffinden Ihres Falles.",
      "sendAgain": "Formular erneut senden",
      "finish": "Zurück zur Startseite"
    }
  },
  ":": ":",
  "The ticket needs one of the following Parameter": "Dieses Ticket benötigt eines der folgenden Parameter",
  "Support Tickets": "Support Tickets",
  "IT Tickets": "IT Tickets",
  "_confirmationText": {
    "removeProductAmount": "Sind Sie sicher, dass Sie die Produktmenge entfernen möchten?",
    "removeParentTicketFromTicket": "Sind Sie sicher, dass Sie das übergeordnete Ticket von diesem Ticket entfernen möchten?",
    "removeChildTicketFromTicket": "Sind Sie sicher, dass Sie das untergeordnete Ticket von diesem Ticket entfernen möchten?",
    "removeAttachmentFromTicket": "Sind Sie sicher, dass Sie diesen Anhang von diesem Ticket entfernen möchten?",
    "removeAddressFromTicket": "Sind Sie sicher, dass Sie diese Adresse aus diesem Ticket entfernen möchten?",
    "reopenTicket": "Sind Sie sicher, dass Sie das Ticket erneut öffnen wollen?",
    "removeTicketFromProductInstance": "Sind Sie sicher, dass Sie dieses Ticket aus dieser Produktinstanz entfernen möchten?",
    "removeProductInstanceFromTicket": "Bist du sicher, dass du diese Produktinstanz von diesem Ticket entfernen möchten?",
    "removeTicketFromCustomer": "Sind Sie sicher, dass Sie dieses Ticket von diesem Kunden entfernen möchten?",
    "removeAddressFromCustomer": "Sind Sie sicher, dass Sie diese Adresse von diesem Kunden entfernen möchten?",
    "deleteTicket": "Sind Sie sicher, dass Sie dieses Ticket löschen möchten?",
    "removeCustomerFromTicket": "Sind Sie sicher, dass Sie diesen Kunden von diesem Ticket entfernen möchten?"
  },
  "_footerProperty": {
    "createdBy": "Erstellt von {user} am {date}",
    "updatedBy": "Zuletzt geändert von {user} am {date}",
    "created": "Erstellt am {date}",
    "updated": "Zuletzt geändert am {date}",
    "noObject": "Keine Ticket Id gefunden"
  },
  "_theme": {
    "title": "Theme",
    "description": "Passen Sie das Aussehen Ihrer Anwendung mit hellen und dunklen Themen an.",
    "light": "Helles Theme",
    "dark": "Dunkeles Theme",
    "system": "Systemeinstellung"
  },
  "_language": {
    "title": "Sprache",
    "description": "Stellen Sie die Sprache Ihrer Anwendung ein. Einige Übersetzungen werden erst nach einem Neuladen der Seite übernommen.",
    "de": "Deutsch",
    "en": "English"
  },
  "_globalSort": {
    "title": "Listen global Sortieren",
    "description": "Globale Liste Sortierrichtung nach Alter festlegen.",
    "oldestFirst": "Aufsteigend (älteste zuerst)",
    "oldestLast": "Absteigend (älteste zuletzt)"
  },
  "_trackingCodeType": {
    "DHL": "DHL",
    "POST": "POST"
  },
  "_ticket": {
    "id": "Backend ID",
    "type": "Typ",
    "refund": "Rückerstattung",
    "shipment_type": "Zustellungart",
    "preorder_group": "Vorbestellungs Gruppe",
    "preorder_prio_group": "Vorbestellung Prioritätgruppe",
    "preorder_state": "Vorbestellung Status",
    "invoice_number": "Rechnungsnummer",
    "_preorder_group": {
      'SP8': "SHIFTphone8"
    },
    "_preorder_prio_group": {
      "normal": "normal",
      "Prio1": "Prio 1",
      "Prio2": "Prio 2",
      "Prio3": "Prio 3",
      "Prio4": "Prio 4",
      "Prio5": "Prio 5",
      "WaitEndOf2024": "Wartet bis Ende 2024",
      "WaitBeginOf2025": "Wartet bis Beginn 2025",
      "WaitOSCertificate": "Wartet auf OSCertifikat",
      "WaitLiteOS": "Wartet auf LiteOS",
      "WaitForCamera": "Wartet auf Kamera",
      "WaitForOfficialRelease": "Wartet auf offizielle Release",
      "WaitFeedbackSP8GSM": "Wartet auf Feedback SP8 GSM",
      "WaitForSP8.1": "Wartet auf SP8.1",
    },
    "preorder_position": "Vorbestellung Position",
    "preorder_order_date": "Vorbestellungsdatum",
    "preorder_estimation": "Vorbestellungs Schätzdatum",
    "_shipment_type": {
      "shipment": "Versand",
      "self_collection": "Selbstabholung"
    },
    "_team": {
      "name": "Team"
    },
    "_watchers": {
      "username": "Beobachter"
    },
    "_protocols": {
      "text": "Protokolltext"
    },
    "_customer": {
      "first_name": "Kundenvorname",
      "name": "Kundenname",
      "last_name": "Kundennachname",
      "email": "Kundenemail",
      "createdAt": "Erstellungsdatum",
      "id": "Kundennummer"
    },
    "_product_instances": {
      "_product": {
        "unique_name": "Produktinstanz Name"
      }
    },
    customer: "Kunde",
    "magento_order_payment_method": "Zahlungsmethode",
    "ticket_id": "Ticket Nummer",
    "magento_order_state": "Magento Status",
    "createdAt": "Erstellungsdatum",
    "updatedAt": "Änderungsdatum",
    "advance_device_deposit": "Pfand für Vorabgerät",
    "name": "Name",
    "description": "Beschreibung",
    "magento_order_number_preorder": "Magento-Bestellung Vorbestellung",
    "magento_order_number_deposit": "Magento-Bestellung Pfand",
    "package_number": "Paketnummer (intern)",
    "shipment_number": "Sendungsnummer",
    "tracking_code": "Tracking-Code",
    "tracking_code_type": "Tracking-Code Typ",
    "owner": "Eigentümer",
    "checked": "Überprüft",
    "_owner": {
      "username": "@:_ticket.owner"
    },
    "team": "Team",
    "watchers": "Beobachter",
    "store": "Lager",
    "state": "Status",
    "priority": "Priorität",
    "_priority": {
      "Medium": "Normal",
      "High": "Hoch",
      "Low": "Niedrig"
    },
    "sub_type": "Untertyp",
    "_sub_type": {
      "refund": "Rückerstattung",
      "Neubestellung": "Neubestellung",
      "loan device": "Leihgerät",
      "upgrade": "Upgrade",
      "residualValueForecast": "Restwertprognose",
      "question": "Anfrage",
      "stolen": "Gestohlen",
      "other": "Sonstiges",
      "exchangeDevice": "Tauschgerät",
      "with costs": "Mit Kosten",
      "guarantee": "Garantie",
      "SL-refurbishment": "SL-Wiederaufarbeitung",
      "goodwill": "Kulanz",
      "unknown": "Unbekannt",
      "customer": "Kunde",
      "production": "Produktion",
      "defect": "Defekt",
      "outdated": "Veraltet",
      "wrongDelivery": "Falschlieferung",
      "repurchase": "Rückkauf",
      "cancelled order": "Widerruf",
      "loaner return": "Rückgabe Leihgerät",
      "repair": "Reparatur",
      "undeliverable": "Nicht verfügbar",
      "cancellation": "Widerruf",
      "recycle": "Recycling",
      "partialDelivery": "Teillieferung",
      "customerPartialDelivery": "Kunden Teillieferung",
      "shipmentInvestigationOrder": "Versand-Nachforschung",
      "null": "",
      "upgradeProduct": "Kombiangebot",
    },
    "_state": {
      "open": "Offen",
      "stolen": "Gestohlen",
      "WaitForGoods": "Warte auf Rückgabe",
      "waitForPreorder": "Warte auf Vorbestellung",
      "inProgress": "In Bearbeitung",
      "closed": "Geschlossen",
      "inProduction": "In Produktion",
      "inOrder": "In Bestellung",
      "preOrder": "Vorbestellung",
      "waitForCustomer": "Warte auf den Kunden",
      "canceled": "Abgebrochen",
      "payed": "Bezahlt",
      "waitForPayment": "Warte auf Zahlung",
      "waitForDevice": "Warte auf Gerät",
      "waitForKVA": "Warte auf KVA",
      "waitForRepair": "Warte auf Reparatur",
      "inRepair": "In Reparatur",
      "readyForShipment": "Bereit für Versand",
      "waitForOrderCreation": "Warte auf Auftragserstellung",
      "undefined": "Status nicht bekannt",
      "demand": "Nachfrage",
      "inquiry": "Rückfrage",
      "ready": "Bereit",
      "waitForRefund": "Warte auf Rückerstattung",
      "waitForPaymentDeposit": "Warte auf Pfand Zahlung",
      "payedDeposit": "Pfand bezahlt",
      "repaired": "Repariert",
      "waitForShipment": "Warte auf Lieferung",
      "waitForChecking": "Warte auf Kontrolle",
      "WaitForInvestigation": "Warte auf Nachforschung",
      "InvestigationFinished": "Nachforschung beendet",
      "creatingKVA": "KVA wird erstellt",
      "waitForCustomer2": "Warte auf den Kunden",
      "readyForShipment2": "Bereit für Versand",
      "waitForGoods2": "Warte auf Rückgabe",
      "readyForShipment3": "Bereit für Versand",
      "waitForRefund2": "Warte auf Rückerstattung",

    },
    "_type": {
      "support": "Support",
      "repair": "Reparatur",
      "book_in": "Einbuchen",
      "book_out": "Ausbuchen",
      "production_order": "Produktions Auftrag",
      "product_order": "Produkt Auftrag",
      "testing": "Test",
      "delivery": "Lieferung",
      "shipment": "Versand",
      "quality_assurance": "Qualitätssicherung",
      "hardware_definition": "Hardware-Definition",
      "special": "Spezial",
      "return": "Rückgabe",
      "recycle": "Recycling",
      "software_update": "Software-Update",
      "hardware_preparation": "Hardware Vorbereitung",
    },
    "custom_data": {
      "repair_diagnosis": "Diagnose",
      "return_reason": "Rückgabegrund",
      "_return_reason": {
        "Cancellation, revocation": "Stornierung, Widerruf",
        "Item does not meet expectations/requirements": "Artikel entspricht nicht den Erwartungen/Anforderungen",
        "I do not like the look of it": "Optik gefällt mir nicht",
        "do not like it too heavy": "zu schwer gefällt mir nicht",
        "do not like hardware/software": "Hardware/Software gefällt mir nicht",
        "do not like item quality poor": "Artikelqualität schlecht gefällt mir nicht",
        "Camera bad": "Kamera schlecht",
        "Voice quality poor": "Sprachqualität schlecht",
        "Battery performance poor": "Akkuleistung schlecht",
        "Feel bad": "Feinfühlung",
        "defective hardware": "defekte Hardware",
        "defective software, Android old, apps not working": "defekte Software, Android alt, Apps funktionieren nicht",
        "Wrong item ordered (customer)": "Falscher Artikel bestellt (Kunde)",
        "incorrect item delivered (SHIFT)": "falscher Artikel geliefert (SHIFT)",
        "Upgrade to better SHIFTPHONE": "Upgrade auf besseres SHIFTPHONE",
        "Customer wants to exchange items": "Kunde möchte Artikel umtauschen",
        "Undeliverable, returned due to incorrect shipping address": "Unzustellbar, Retoure wegen falscher Lieferadresse",
        "Customer did not pick up item at post office": "Kunde hat Artikel nicht bei der Post abgeholt",
        "Does not correspond to the shipping conditions, package does not fit in the mailbox of the packing station": "Entspricht nicht den Versandbedingungen, Paket passt nicht in den Briefkasten der Packstation",
        "Delivery with defect": "Lieferung mit Defekt"
      },
      "testing": { state: "Testeing Status" },
      "todo": "Aufgabe",
      "residualOrder": {
        "productId": "Rückkauf Produkt",
        "originalPrice": "Bezahlter Preis ",
        "age": "Gerätealter",
        "surrenderValue": "Rückkaufwert"
      },

    },
    "product_instances": "Produktinstanzen",
    "product": "Produkt",
    "due_date": "Fälligkeitsdatum",
    "magento_order_number": "Magento Bestellnummer"
  },
  "_team": {
    "Repair": "Reparatur",
    "Support": "Support",
    "Dispatch": "Versand",
    "IT": "IT",
    "Finance": "Finanzen",
    "Return": "Rückgabe",
    "Receipt": "Annahme"
  },
  "_notification_item": {
    "sub_type": "Untertyp",
    "priority": "Priorität",
    "state": "Status",
    "name": "Name",
    "owner": "Eigentümer",
    "description": "Beschreibung",
    "changed": "wurde geändert",
    "team": "Weiterleitung des Tickets an das Team",
    "from": "von",
    "to": "auf",
    "watchers": "Neue(r) Beobachter wurde(n) hinzugefüght",
    "new Protocol Entity": "Ein neuer Eintrag in das Protokoll wurde hinzugefügt",
    "configNeeded": "Zusätzliche Benachrichtigungskonfiguration erforderlich",
    "last_update_user": "Letzte Aktualisierung durch Benutzer",
  },
  "_addressType": {
    "billing": "Rechnungsadresse",
    "shipping": "Lieferadresse"
  },
  "_address": {
    "type": {
      "billing": "Rechnungsanschrift",
      "shipping": "Versandanschrift"
    },
    "company": "Firma",
    "firstname": "Vorname",
    "lastname": "Nachname",
    "name": "Name",
    "email": "E-Mail",
    "street": "Straße",
    "postcode": "PLZ",
    "city": "Stadt",
    "country_id": "Land",
    "country": "Land",
  },
  "_table": {
    "messages": {
      "new_lines": "Neue Zeilen",
      "removed_lines": "Entfernte Zeilen",
      "lastSeen": "Zuletzt gesehen",
      "lastUpdated": "Zuletzt aktualisiert",
      "total_lines": "Gesamtzahl der Zeilen",
      "new_in_filter": "Neu im Filter",
    },
    "filter": {
      "condition": {
        "_title": {
          "equals": "gleich",
          "notEquals": "nicht gleich",
          "greaterThan": "größer als",
          "lessThan": "weniger als",
          "between": "zwischen",
          "greaterThanOrEqual": "größer als order gleich",
          "lessThanOrEqual": "weniger als oder gleich",
          "contains": "enthält",
          "notContains": "enthält nicht",
          "startsWith": "beginnt mit",
          "endsWith": "endet mit",
          "in": "in",
          "not in": "nicht in",
          "empty": "leer",
          "not empty": "nicht leer",
          "Is": "ist"
        }
      }
    }
  },
  "_product-instance": {
    "state": "Status",
    "id": "Produktinstanz-ID",
    "createdAt": "Erstellungsdatum",
    "updatedAt": "Letzte Aktualisierung",
    "_state": {
      'produced': "Produziert",
      'shipping': "Versendet",
      'inStore': "Im Lager",
      'outOfStore': "Außerhalb vom Lager",
      'supportCase': "Supportfall",
      'customer': "Kunde",
      'stolen': "Gestohlen",
      'inTesting': "wird getestet",
      "unknown": "Unbekannt",
      "complete": "Abgeschlossen",
      "inRepair": "In Reparatur",
      "sold": "Verkauft",
      "recycled": "Recycling",
      "defined": "Vordefiniert",
      "flashed": "geflasht",
    },
    "software_version": "Softwareversion",
    "key_box": "Schlüsselkiste",
    "quality": "Qualität",
    "_quality": {
      "unknown": "Unbekannt",
      "inProduction": "In Produktion",
      "recycled": "Recycling",
      "postProductionTested": "Post-Produktion getestet",
      "QualityA": "Qualität A",
      "SLA": "Second Live A",
      "SLB": "Second Live B",
      "SLC": "Second Live C",
      "tested": "Getestet",

    },
    "description": "Beschreibung",
    "_product": {
      "unique_name": "Eindeutiger Name",
      "version": "Version",
      "variant": "Variante",
    },
    "subState": "Unterstatus",
    "imei_1": "IMEI 1",
    "imei_2": "IMEI 2",
    "serial_number": "Seriennummer",
    "Description": "",
    "mac_bt": "MAC Bluetooth",
    "mac_wifi": "MAC WiFi",
    "mac_lan": "MAC LAN",
    "meid": "MEDI",
  },
  "_missingtranslation": {
    "id": "id",
    "key": "key",
    "counter": "counter",
    "language": "language",
    "last_hit": "last_hit",
  },
  "_customer": {
    "first_name": "Vorname",
    "last_name": "Nachname",
    "email": "Haupt E-Mail",
    "email2": "Zweite E-Mail",
    "email3": "Dritte E-Mail",
    "description": "@:description",
    "address_text": "Old address in Text fromat",
    "phone": "@:phone",
    "_addresses": {
      "street": "@:_address.street",
      "postcode": "@:_address.postcode",
      "city": "@:_address.city",
      "country_id": "@:_address.country_id"
    },
    "updatedAt": "zuletzt geändert",
    "company": "Firma",
    "gender": "Geschlecht",
    "title": "Titel",
    "createdAt": "erstellt am",
    "id": "Kunden ID (intern)",
  },
  "_template": {
    "id": "id",
    "name": "Name",
    "description": "Beschreibung",
    "notes": "Notizen",
    "note": "Notiz",
  },
  "_repairParts": {
    "repairParts": "Reparaturteile",
    "price": "Preis",
    "repairCost": "Reparaturkosten",
    "totalCost": "Gesamtkosten",
    "noTax": "Gesammtkosten (Netto):",
    "withTax": "Gesammtkosten mit Steuer (19%):",
  },
  "_defineHardware": {
    "OUI": "OUI",
    "TAC": "TAC",
    "MAC Start": "MAC Start",
    "IMEI Start": "IMEI Start",
    "Amount": "Amount",
    "Generate Wifi MAC": "Generate Wifi MAC",
    "Generate Bluetooth MAC": "Generate Bluetooth MAC",
    "Generate IMEI 1": "Generate IMEI 1",
    "Generate IMEI 2": "Generate IMEI 2",
    "Define": "Define",
    "check existence": "prüfe existenz",
    "Checking product existence": "Überprüfe aut existierende Produktinstanzen",
    "create": "erstellen",
    "Existing Product": "Vorhandenes Produkt",
    "Creating hardware": "Erstelle Hardware...",
    "existing products": "Vorhandene ProdukteInstanzen",
    "Done": "Fertig",
    "Export": "Export",
  },
  "_tableConfig": {
    size: {
      title: 'Anzahl der Zeilen in einer Tabelle',
      description: 'Anzahl der Zeilen die standartmäßig in einer Tabelle angezeigt werden sollen'
    }
  },
  "_addressValidation": {
    "title": "Adressenprüfung",
    "noMatchText": "Keine Addresse wurde gefunden",
    "currentAddress": "Aktuelle Adresse",
    "copyThisAddress": "Adresse kopieren",
    "close": "Schließen",
  },
  "_mobileapp": {
    "main": {
      "testing": {
        "title": "Test-Ticket",
        "subtitle": "Scanne ein Gerät und führe direkt ein Test durch."
      },
      "shipping": {
        "title": "Versand-Ticket",
        "subtitle": "Erstelle und konfiguriere ein Versandticket direkt von deinem mobilen Gerät aus."
      },
      "return": {
        "title": "Rückgabe-Ticket",
        "subtitle": "Erstelle ein Rückgabe-Ticket für zurückgesendete Produkte."
      },
      "mobile": {
        "title": "Mobile-Aufruf",
        "subtitle": "Scanne einen QR-Code, um das entsprechende Gerät direkt auf deinem Mobilgerät zu öffnen."
      },
      "desktop": {
        "title": "Desktop-Aufruf",
        "subtitle": "Scanne ein Gerät, um es direkt in deinem Desktop-System (Intra) zu öffnen."
      },
      "autofill": {
        "title": "Autoausfüllen",
        "subtitle": "Scannen einen QR- oder Barcode und übertrage diesen direkt in das aktive Textfeld auf dem Desktop."
      }
    },
    "action": {
      "back": "Zurück",
      "cancelRequest": "Anfrage abbrechen",
    },
    "shippingInfo": {
      "title": "Bestellnummer Online-Shop",
      "text": "Der Online-Shop vergibt Auftragsnummern. Diese Nummer befindet sich auf der Rechnung.",
    },
    "autofillInfo": {
      "title": "Textfeld auswählen",
      "text": "Scanne ein Barcode oder QR-Code, um den Inhalt in das aktive Textfeld auf dem Desktop zu übertragen.",
    },
    "logoutState": {
      "title": "Bitte melde dich an!",
      "text": "In deinem Desktop-System (Intra) unter deinem Profil findest du einen QR-Code, um dich mit deinem mobilen Gerät einzuloggen.",
    },
    "createProductShipment": {
      "order": "Bestellung",
      "trackingCode": "Tracking-Code",
      "pleaseScanTrackingCode": "Tracking-Code bitte einscannen",
      "scannedProduct": "Gescannte Produkte",
      "pleaseScanProduct": "Bitte Produkt scannen",
      "magentoItems": "Bestellte Artikel",
      "noMagentoItems": "Keine Artikel gefunden",
    }
  },
} as any;

config._ticket._team._name = config._team
config["_product-instance"].tickets = config._ticket;
config["_magentoOrder"].addresses = config._address;

const fixtConfig = config;

export default fixtConfig;
